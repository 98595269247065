import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Contract } from '../store/contract/contract.reducer';
import { getCurrentContract } from '../store/contract/contract.selectors';
import { User } from '../store/user/user.reducer';
import { getCurrentUser } from '../store/user/user.selectors';
import * as _ from 'lodash';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  private readonly currentContract$ = this.store.select(getCurrentContract);
  private readonly currentUser$ = this.store.select(getCurrentUser);
  private currentContract?: Contract;
  private currentUser?: User;

  public readonly ready$ = new BehaviorSubject(false);

  public readonly userRole$ = new BehaviorSubject('read');
  private userRole?: string;

  constructor(
    private store: Store,
    private configService: ConfigService,
  ) {
    this.userRole$.subscribe(role => (this.userRole = role));

    this.currentContract$.subscribe(contract => {
      this.currentContract = contract;
      this.checkPermissions();
    });

    this.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.checkPermissions();
    });
  }

  private checkPermissions() {
    if (this.currentUser && this.currentContract) {

      // partner role overrides contract role
      const partnerRelation = _.find(this.currentUser.relations?.partners, {
        partner_uuid: this.configService.partnerUuid
      });
      const contractRelation = this.currentContract
        ? _.find(this.currentUser.relations?.contracts, {
            contract_uuid: this.currentContract.object_uuid
          })
        : undefined;


      const permissionRank = ['read', 'write', 'admin', 'owner'];
      if (partnerRelation && contractRelation) {
        // get the higher permissions
        if (permissionRank.indexOf(partnerRelation.user_role) > permissionRank.indexOf(contractRelation.user_role!)) {
          this.userRole$.next(partnerRelation.user_role);

        } else {
          this.userRole$.next(contractRelation.user_role!);
        }


      } else if (partnerRelation) {
        this.userRole$.next(partnerRelation.user_role);


      } else if (contractRelation) {
        this.userRole$.next(contractRelation.user_role!);

      }

      this.ready$.next(true);
    } else {
      this.userRole$.next('read');
    }


  }

  /**
   * returns if the user has owner privileges on the current contract
   * Do not use before this.userRole$ resolved!
   */
  hasPrivilegeOwner() {
    return this.userRole === 'owner';
  }

  /**
   * returns if the user has admin privileges on the current contract
   * Do not use before this.userRole$ resolved!
   */
  hasPrivilegeAdmin() {
    return this.userRole === 'owner' || this.userRole === 'admin';
  }

  /**
   * returns if the user has write privileges on the current contract
   * Do not use before this.userRole$ resolved!
   */
  hasPrivilegeWrite() {
    return this.userRole === 'write' || this.userRole === 'owner' || this.userRole === 'admin';
  }

  /**
   * returns if the user has read privileges on the current contract
   * Do not use before this.userRole$ resolved!
   */
  hasPrivilegeRead() {
    return true;
  }

  /**
   * returns if current user role is explicit "owner" on the current contract.
   * Do not use before this.userRole$ resolved!
   */
  isOwner() {
    return this.userRole === 'owner';
  }

  /**
   * returns if current user role is explicit "admin" on the current contract.
   * Do not use before this.userRole$ resolved!
   */
  isAdmin() {
    return this.userRole === 'admin';
  }

  /**
   * returns if current user role is explicit "write" on the current contract.
   * Do not use before this.userRole$ resolved!
   */
  isWrite() {
    return this.userRole === 'write';
  }

  /**
   * returns if current user role is explicit "read" on the current contract.
   * Do not use before this.userRole$ resolved!
   */
  isRead() {
    return this.userRole === 'read';
  }

  /**
   * get current role
   * Do not use before this.userRole$ resolved!
   */
  getRole() {
    return this.userRole;
  }

  checkIfRoleIs(appUserRole: string) {
    if (appUserRole.match(/^([!>=]*)([a-z]+)$/)) {
      const prefix = RegExp.$1;
      const checkRole = RegExp.$2;

      switch (checkRole) {
        case 'owner':
          if (prefix === '>=') {
            return this.hasPrivilegeOwner();
          } else {
            if (this.isOwner()) {
              return prefix !== '!';
            } else {
              return prefix === '!';
            }
          }
          break;

        case 'admin':
          if (prefix === '>=') {
            return this.hasPrivilegeAdmin();
          } else {
            if (this.isAdmin()) {
              return prefix !== '!';
            } else {
              return prefix === '!';
            }
          }
          break;

        case 'write':
          if (prefix === '>=') {
            return this.hasPrivilegeWrite();
          } else {
            if (this.isWrite()) {
              return prefix !== '!';
            } else {
              return prefix === '!';
            }
          }
          break;

        case 'read':
          if (prefix === '>=') {
            return this.hasPrivilegeRead();
          } else {
            if (this.isRead()) {
              return prefix !== '!';
            } else {
              return prefix === '!';
            }
          }
          break;
      }
    }
  }
}
